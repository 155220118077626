<script setup lang="ts">
import { ref, computed } from 'vue'
import bottomSheet from '/~/core/bottom-sheet'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { useWithdraw } from '/~/composables/withdraw/use-withdraw'

const props = defineProps<{
  method: any
}>()

const emit = defineEmits<{
  (event: 'hide'): void
}>()

const { fetchPaymentMethods, deletePaymentMethod } = usePaymentMethods()
const { getWithdrawalBankAccounts } = useWithdraw()

const removing = ref(false)

const isVerified = computed(
  () => props.method.status?.toLowerCase() === 'verified'
)

function close() {
  emit('hide')
}

function update() {
  fetchPaymentMethods()
  getWithdrawalBankAccounts()
  close()
}

function verifyAccount() {
  bottomSheet.show('method-verify', {
    to: 'menu-modal',
    props: {
      method: props.method,
      onConfirm: () => {
        update()
      },
      onFailed: () => {
        update()
      },
      onCancel: () => {
        close()
      },
    },
  })
}

function removeAccount() {
  bottomSheet.show('confirm', {
    to: 'menu-modal',
    props: {
      title: 'Remove account',
      description:
        'Are you sure you want to remove this account? This action cannot be undone.',
      icon: {
        path: 'heroicons/solid/exclamation-triangle',
        color: 'text-fg-error',
      },
      onConfirm: async () => {
        removing.value = true

        try {
          await deletePaymentMethod(props.method.id)
          getWithdrawalBankAccounts()
        } catch (error) {
          console.error('modal', error)
        } finally {
          removing.value = false
        }
      },
    },
    on: {
      hide: close,
    },
  })
}
</script>

<template>
  <div class="bg-white">
    <div class="relative border-b p-5">
      <h2
        class="w-full truncate px-9 text-center text-xl leading-6 text-eonx-neutral-800"
      >
        {{ method.accountName }}
      </h2>
      <base-button
        icon="heroicons/outline/x-mark"
        :size="32"
        class="!absolute right-4 top-4"
        @click="close"
      />
    </div>
    <button
      v-if="!isVerified"
      class="flex w-full items-center justify-between border-b p-5 hover:bg-eonx-neutral-50"
      @click="verifyAccount"
    >
      <span>Verify account</span>
      <base-icon svg="v2/custom/chevron-right-outline" :size="16" />
    </button>
    <button
      class="flex w-full items-center justify-between border-b p-5 hover:bg-eonx-neutral-50"
      :class="{
        'pointer-events-none opacity-50': removing,
      }"
      :disabled="removing"
      @click="removeAccount"
    >
      <span>Remove account</span>
      <base-loader v-if="removing" ref="the-loader" size="xs" />
      <base-icon v-else svg="v2/custom/chevron-right-outline" :size="16" />
    </button>
  </div>
</template>
